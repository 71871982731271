import React from "react"
import { SectionTitle, SectionTitle2 } from "../../components/styled"
import JobContent from "../../components/JobContent"
import SearchJob from "../../components/SearchJob"
import { Seo } from "../../components/seo"
import useJobs from "../../hooks/useJobs"
import Contact from "../../components/Contact"
const JobsListingEn = () => {
  const dataJobs = useJobs("English")
  return (
    <section className="container jobs-listing english-page-body">
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-lg-9">
          <div className="jobs-listing__search">
            <SectionTitle>
              Mexico Job Listings, Employment, and Career Opportunities
            </SectionTitle>
            <hr className="u-line-bottom" />
            <div className="jobs-listing__search--box">
              <SearchJob lang="en" />
            </div>
          </div>
          <div className="jobs-listing__job-info">
            <SectionTitle2>Job Information</SectionTitle2>
            <hr className="u-line-bottom" />
            {dataJobs.map((jobs, i) => (
              <React.Fragment key={"jobContent_" + i}>
                <JobContent jobs={jobs} lang={"en"} />
              </React.Fragment>
            ))}
            <Contact lang={"en"} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobsListingEn

export const Head = () => {
  const dataJobs = useJobs("English")
  return (
    <Seo
      title={`メキシコ求人・就職・転職情報`}
      description={dataJobs[0]?.JobDescription}
      image={dataJobs.length !== 0 ? dataJobs[0]?.types[0]?.image?.url : ""}
      url={`https://www.919mexico.com/en/jobs-listing`}
      language="en"
    />
  )
}
